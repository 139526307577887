import React, { useEffect, useState } from 'react';
import  'bootstrap/dist/css/bootstrap.min.css';
import banner from '../components/partner-detailpage-banner.jpeg';
import Nav from '../components/Nav';
import {Link, graphql} from 'gatsby'

import loadable from '@loadable/component'
// import { Container,Row,Col,Tabs,Tab } from 'react-bootstrap';
// import Login from './Login';

const Footer = loadable(() => import('../components/Footer'))
function Privacy(){
return(
    <>
<Nav/>
              <section className="bg-light-gray padding-35px-tb page-title-small top-space-verum">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8 col-md-6 d-flex flex-column justify-content-center">
                        <h1 className="alt-font text-extra-dark-gray font-weight-600 no-margin-bottom">Privcay Policy</h1>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className="wow fadeIn min-height-400px cover-background" style={{backgroundImage: `url('images/banner-dezyne-new.jpg');`,marginTop:"114px;"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 wow fadeInLeft">
                        <h3 className="inner-banner-title text-white">Privacy Policy</h3>
                    </div> 
                    <div className="col-12 col-lg-4 wow fadeInRight" data-wow-delay="0.2s"></div>
                </div>
            </div>
        </section> */}
        <section className="padding-70px-tb wow fadeInUp">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <h3 className="title-section alt-font text-center text-dark-gray letter-spacing-1">VERUM SOFTWARE TOOLS B.V.</h3>
                        <p>We kindly inform you that Verum Software Tools B.V. with its registered office in Eindhoven, the Netherlands (hereinafter also referred to as "<strong>Verum</strong>") processes your personal data for the purpose and in the manner described in this document (hereinafter referred to as "<strong>Privacy Policy</strong>"). This Privacy Policy applies to the processing of personal data in connection with:</p>
                        <ol type="a" className="list-style-1 list-style1-1 p-0 margin-25px-left">
                        	<li>browsing Verum's website - <a href="https://www.verum.com/" className="text-green" target="_self">https://www.verum.com/</a> (hereinafter referred to as: "<strong>Verum's website</strong>");</li>
                            <li>provision of services, including those provided by electronic means (such as for example: enabling contact with Verum through contact forms, helpdesk forms, support forms and registering on the forum to hold discussion with Dezyne Community);</li>
                            <li>sale of Verum's products or providing the product in trial version; </li>
                            <li>e-mail and traditional correspondence addressed to Verum;</li>
                            <li>Verum's academic programs and student team sponsorships;</li>
                            <li>Verum's marketing activities and promotion of Verum's brand within social platforms such as Facebook, LinkedIn as well as through YouTube (hereinafter also referred to as "<strong>Social platforms</strong>").</li>
                        </ol>
                        <p>Verum ensures that the processing of personal data complies with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (hereinafter referred to as "<strong>GDPR</strong>").</p>
                        
                        <h6 className="alt-font margin-10px-bottom margin-30px-top">1. Who is the controller of personal data?</h6>
                        <p>The controller of the personal data is in general Verum Software Tools B.V., at Luchthavenweg 81 – 2.29a, 5657 EA Eindhoven, the Netherlands. However in relation to Social platforms Verum also acts as a joint controller with Facebook, LinkedIn or YouTube.</p>
                        <p><strong>You can contact Verum using the following e-mail address: <a href="mailto:info@verum.com" className="text-green" target="_blank">info@verum.com</a>.</strong></p>
                        <h6 className="alt-font margin-10px-bottom">2. What is the purpose of personal data processing?</h6>
                        <p>Your personal data are processed for the purposes of:</p>
                        <ol type="a" className="list-style-1 list-style1-1 p-0 margin-25px-left">
                        	<li>entering into contract for the sale of Verum's products or providing you with their trial versions;</li>
                            <li>identification of the sender and handling the inquiry sent via contact forms, e-mail, postal mail, Verum's forum and functionalities available on Social platforms (chat, messages, comments), registered forum user verification;</li>
                            <li>verification of meeting the criteria for academic programs and student team sponsorship;</li>
                            <li>providing services other than those indicated in point b) hereinabove;<br/>- the legal basis: processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into contract, i.e. Article 6(1)(b) GDPR;</li>
                            <li>enabling the conclusion and performance of a contract with an entity of which you are a representative or have been appointed by this entity to take actions in connection with the performance of the contract – the legal basis of the processing is Verum's legitimate interest in maintaining business relationships, entering into contracts with your mandate and ensuring its proper execution, i.e. Article 6(1)(f) GDPR;</li>
                            <li>technical, administrative and for the purposes of ensuring systems security, systems management - the legal basis of the processing is Verum's legitimate interest in ensuring service security, removing failures and errors, i.e. Article 6(1)(f) GDPR;</li>
                            <li>analytics, statistics and improving the functioning of the Verum's website - the legal basis of the processing is the Verum's legitimate interest consisting in analyzing the activity, users' preferences and the way they use Verum website in order to improve their functionality and improve them and the services provided through them, i.e. Article 6(1)(f) GDPR;</li>
                            <li>establishing and pursuing claims, defending against claims - the legal basis for the processing is Verum's legitimate interest in protecting its rights and defending against claims, i.e. Article 6(1)(f) of the GDPR;</li>
                            <li>displaying marketing content and sending newsletters - the legal basis for the processing is Verum's legitimate interest in promoting Verum's products, services, activities, i.e. Article 6(1)(f) GDPR;</li>
                            <li>informing via Verum's website and Social platforms and activities, to promote Verum's activities, to communicate with the user by means of the functionalities available on Social platforms - the legal basis for the processing is Verum's legitimate interest in undertaking the above activities, i.e. Article 6(1)(f) of the GDPR;</li>
                            <li>Verum's performance of legal obligations, including tax and archiving obligations concerning Verum, the legal basis: processing is necessary for compliance with a legal obligation to which the controller is subject i.e. Article 6(1)(c) of the GDPR.</li>
                        </ol>
                        <h6 className="alt-font margin-10px-bottom margin-30px-top">3. Is it necessary to provide personal data?</h6>
                        <p>Providing personal data is voluntary, however, the consequence of failing to provide it will be inability to access all offered services or inability to enter into the agreement.</p>
                        <p>In some cases personal data, that Verum processes, may not be made available to Verum directly by you. This means that it has been obtained by Verum from publicly available sources, such as the Internet or your personal data was provided by your employer with whom Verum maintains a business relationship. In this case, Verum processes your personal data to the following extent: name, middle name, surname, e-mail address, contact telephone number.</p>
                        <h6 className="alt-font margin-10px-bottom">4. What are the rights towards the controller of personal data in the scope of the processed data?</h6>
                        <p>The data subject has the right to access his/her personal data and the right to rectify, erase, limit processing, the right to personal data portability, the right to object, as well as the right to lodge a complaint to the applicable supervisory authority.</p>
                        <p>As a rule, Verum shall consider and comply with the data subject's request without delay, within a period not exceeding one month, unless a shorter period is provided for by law. If necessary, the deadline may be extended for another two months due to the complexity of the request or the number of requests. Within one month of receipt of the request, the Verum shall inform the data subject of such extension, stating the reasons for the delay. If there are grounds for refusal to comply with the request, Verum shall inform such person, within the aforementioned period, of:</p>
                        <ol type="a" className="list-style-1 list-style1-1 p-0 margin-25px-left">
                        	<li>a negative consideration of the request;</li>
                            <li>his/her rights resulting from the refusal.</li>
                        </ol>
                        <h6 className="alt-font margin-10px-bottom">5. With whom do we share personal data?</h6>
                        <p>Personal data may be shared with external recipients such as: cooperating companies, advisors, couriers, banks, payment operators, IT providers, insurers, host providers, communication tools providers, accounting offices, law firms, only to fulfill the purpose for which they were collected.</p>
                        <p>Verum may also transfer personal data to authorities or third parties who will indicate an appropriate legal basis for this purpose.</p>
                        <p>Verum does not transfer personal data to a third country (i.e. outside the European Economic Area or to international organizations), however your data may be transferred outside the European Economic Area or to an international organization by other controllers of personal data of what you should be informed by those controllers (e.g. Facebook).</p>
                        <h6 className="alt-font margin-10px-bottom">6. How long do we keep personal data?</h6>
                        <p>As a rule, personal data processed in connection with:</p>
                        <ol type="a" className="list-style-1 list-style1-1 p-0 margin-25px-left">
                        	<li>the conclusion and performance of a contract and provision of services – will be retained for the duration of the contract, however server logs on the forum containing the IP address of all requests to the server will be stored for no longer than 90 days and IP addresses associated with registered forum users and their posts no more than 5 years;</li>
                            <li>taking action at your request prior to entering into a contract – will be retained for the period necessary to perform the purpose of processing, but no longer than for the period of performance of the subsequently concluded contract, and if no contract has been concluded then for no longer than 6 months;</li>
                            <li>conducting marketing on the basis of a legitimate interest of the controller - they will be retained until you raise an effective objection to the processing;</li>
                            <li>pursuing the Verum's legitimate interests other than marketing – will be retained until you successfully object to their processing due to the particular situation of the data subject;</li>
                            <li>compliance with a legal obligation to which Verum is subject – will be retained for the periods specified in generally applicable Dutch law.</li>
                        </ol>
                        <p>The aforementioned storage periods of personal data may change if the personal data are necessary to establish, assert or defend against possible claims (as a rule, until the claims expire) or in a situation required by generally applicable laws.</p>
                        <p>After the expiration of the storage period the Verum deletes or anonymizes (irreversibly) the personal data. Then the information is deprived of the nature of personal data.</p>
                        <p>Your personal data collected by other data controllers - e.g. Facebook - are subject to retention on the principles specified by the regulations of these entities.</p>
                        <h6 className="alt-font margin-10px-bottom">7. How do we ensure the safety of your personal data?</h6>
                        <p>Verum cares about the security of personal data provided by you and ensures that they are protected against access by unauthorized persons, as well as against other cases of disclosure or loss, and against destruction or unauthorized modification of the personal data and information indicated, through the use of appropriate technical and organizational safeguards indicated by law.</p>
                        <p>Verum, in the processing of personal data, also uses the services of personal data processors. They process personal data on the basis of an entrustment, and Verum ensures that the processing entities guarantee an adequate level of security and confidentiality of personal data processing.</p>
                        <p>We are not responsible for the privacy policies and practices of other websites, even if you access them through links from Verum's website and we recommend that you check the policies of each website you visit.</p>
                        <h6 className="alt-font margin-10px-bottom">8. Do we undertake decisions on your affairs by automated means or profiling?</h6>
                        <p>Verum does not make decisions that are based solely on automated processing, which have legal effects for the data subject or similarly significantly affect the data subject. Verum also does not create data's subject profiles based on the personal gathered personal data.</p>
                        <h6 className="alt-font margin-10px-bottom">9. Cookies and other tracking technologies</h6>
                        <p>Verum does not use tracking cookies or any other devices installed on the end-user device to gather your personal data, except for Verum's forum available at forum.verum.com which uses necessary cookies for log-in services available to users. Verum uses essential cookies for the proper functioning of the forum. These files cannot be deactivated by any of the functions of the forum website. The possible processing of personal data in connection with the use of necessary cookies is based on the premise that the processing is necessary for the performance of the contract for the provision of services by electronic means (Article 6(1)(b) GDPR). None of the cookies used by Verum are harmful to the users' devices. They also do not change the settings of devices or software belonging to the user.</p>                        
                        <h6 className="alt-font margin-10px-bottom">10. Change of the privacy policy</h6>
                        <p>The Privacy Policy is effective as of 1 October 2022. If you are a newsletter subscriber or registered user of Verum's forum you will be informed about changes in the Privacy Policy by e-mail, using the e-mail address indicated in newsletter subscription or Verum's forum account. If the changes are not accepted, you may unsubscribe the newsletter or delete your account. The amended Privacy Policy will also be made available on Verum's website. Information on amendments to the Privacy Policy will be sent to the user mentioned above and made available on Verum's website 14 days before the amendments to the Privacy Policy come into force.</p>
                        <p>An amendment to the Privacy Policy shall not affect the rights and obligations of the data subject, or other circumstances that occurred before the amendment came into force, unless otherwise provided by generally applicable law.</p>
                    </div>
                </div>
            </div>
        </section>
<Footer/>
</>
);
}
export default Privacy;